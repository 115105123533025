import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'next/router';
import { ArrowLeft } from 'phosphor-react';
import {
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Eye, EyeSlash, GoogleLogo, FacebookLogo } from 'phosphor-react';

import { facebookLogin, googleLogin } from '../../../actions/oauth';
import { signup } from '../../../actions/auth';
import TextField from '../../utility/TextField';
import validations, { composeValidators } from '../../../lib/validation';
import { buttonStyles } from '../../../styles/base';
import { normalizeText, normalizePhone } from '../../../lib/helpers';
import { isMobile, isTablet } from '../../../lib/getDeviceSize';
import { ToriiPlum } from '../../utility/Icons';
import style from '../../../styles/sass/components/SignupDialog.module.scss';
import { styles } from './styles';

export interface Props {
  children?: any;
  classes: any;
  code?: boolean;
  dispatch: any;
  postAuthPath: string;
  router: any;
  shrinkWidth?: boolean;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
  provider?: any;
  entrancePath: string;
  signupText?: string;
}

export interface State {
  currentStep: number;
  width: number;
  showPassword: boolean;
}

/**
 * Form for users to sign up for a Torii account.
 * @constructor @extends
 * @param {Object} props
 */
export class SignupForm extends React.Component<Props, State> {
  moreOptionsRef: any;
  constructor(props: Props) {
    super(props);
    this.moreOptionsRef = React.createRef();
    this.state = {
      currentStep: 0,
      width: 350,
      showPassword: false,
    };
  }

  componentDidMount() {
    this.updateDimension();
  }

  updateDimension = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  nextSlide = () => {
    this.setState(
      {
        currentStep: this.state.currentStep + 1,
      },
      () => {
        if (this.moreOptionsRef && this.moreOptionsRef.current) {
          this.moreOptionsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      },
    );
  };
  previousSlide = () => {
    this.setState({
      currentStep: this.state.currentStep - 1,
    });
  };

  handleSubmit = (values: any) => {
    const {
      dispatch,
      postAuthPath,
      router,
      utmCampaign,
      utmMedium,
      utmSource,
      utmTerm,
      entrancePath,
    } = this.props;
    values.signupPath = router.asPath.split('?')[0];
    values.utmCampaign = utmCampaign;
    values.utmMedium = utmMedium;
    values.utmSource = utmSource;
    values.utmTerm = utmTerm;
    values.entrancePath = entrancePath;

    // If the user navigates from the agents' page, toriiAgentId will be sent with the request body.
    if (values.signupPath.includes('torii-agent')) {
      const { provider } = this.props;
      values.toriiAgentId = provider.id;
    }
    dispatch(signup(values, postAuthPath));
  };

  handleFacebook = () => {
    const {
      postAuthPath,
      router,
      utmCampaign,
      utmMedium,
      utmSource,
      utmTerm,
      entrancePath,
    } = this.props;
    this.props.dispatch(
      facebookLogin(
        true,
        postAuthPath,
        router.asPath.split('?')[0],
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        entrancePath,
      ),
    );
  };

  handleGoogle = () => {
    const {
      postAuthPath,
      router,
      utmCampaign,
      utmMedium,
      utmSource,
      utmTerm,
      entrancePath,
    } = this.props;
    this.props.dispatch(
      googleLogin(
        true,
        postAuthPath,
        router.asPath.split('?')[0],
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        entrancePath,
      ),
    );
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  renderContents = (handleSubmit: any, valid: any) => {
    const { classes, signupText } = this.props;
    const paddingHeight = isMobile() ? 0 : 25;

    const signupTextToRender = !signupText ? (
      <span>
        Sign up for access to millions of listings, property data, market
        trends, and more.
      </span>
    ) : (
      signupText
    );

    const steps = [
      {
        component: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <ToriiPlum />
            <span className="heading">{signupTextToRender}</span>
            <div className="sign-up-dialog-form-container">
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div className="input-container" style={{ marginRight: 5 }}>
                  <InputLabel id="first-name" className="input-label">
                    First Name
                  </InputLabel>
                  <Field
                    autoComplete="off"
                    component={TextField}
                    format={normalizeText}
                    fullWidth
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    validate={validations.required}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    className="input"
                  />
                </div>
                <div className="input-container" style={{ marginLeft: 5 }}>
                  <InputLabel id="last-name" className="input-label">
                    Last Name
                  </InputLabel>
                  <Field
                    autoComplete="off"
                    component={TextField}
                    format={normalizeText}
                    fullWidth
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    validate={validations.required}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    className="input"
                  />
                </div>
              </div>
              <div className="input-container">
                <InputLabel id="email-label" className="input-label">
                  Email Address
                </InputLabel>
                <Field
                  component={TextField}
                  format={normalizeText}
                  fullWidth
                  name="email"
                  type="email"
                  placeholder="email@email.com"
                  validate={composeValidators(
                    validations.required,
                    validations.email,
                  )}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="input"
                />
              </div>
              <div className="input-container">
                <InputLabel id="phone-number-label" className="input-label">
                  Phone Number
                </InputLabel>
                <Field
                  autoComplete="off"
                  component={TextField}
                  format={normalizePhone}
                  fullWidth
                  name="phoneNumber"
                  type="text"
                  placeholder="123-456-789"
                  validate={composeValidators(
                    validations.required,
                    validations.phoneNumber,
                  )}
                  className="input"
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ inputMode: 'numeric' }}
                />
              </div>
              {!this.props.code && (
                <div className="input-container">
                  <InputLabel id="password-label" className="input-label">
                    Password
                  </InputLabel>
                  <Field
                    component={TextField}
                    format={normalizeText}
                    fullWidth
                    name="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                    validate={composeValidators(
                      validations.minLength8,
                      validations.required,
                    )}
                    className="input"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                            disableRipple
                            style={{
                              backgroundColor: 'transparent',
                              marginRight: 8,
                            }}
                          >
                            {this.state.showPassword ? (
                              <Eye size={32} color="#443461" weight="regular" />
                            ) : (
                              <EyeSlash
                                size={32}
                                color="#443461"
                                weight="regular"
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              {this.props.code && (
                <div
                  className="row col-xs-12 between-xs no-padding-left"
                  style={{ paddingTop: paddingHeight }}
                >
                  <Field
                    autoComplete="off"
                    className="col-xs-12 col-sm-6 no-padding-left left-input"
                    component={TextField}
                    format={normalizeText}
                    label="Password"
                    name="password"
                    required
                    type="password"
                    validate={validations.required}
                  />
                  <Field
                    autoComplete="off"
                    className="col-xs-12 col-sm-6 no-padding-left"
                    component={TextField}
                    format={normalizeText}
                    label="Code (optional)"
                    name="signupCode"
                    type="text"
                  />
                </div>
              )}
              <span className="terms-condition">
                By creating an account, you agree to the{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.toriihomes.com/legal/terms"
                  className="text-link"
                >
                  terms of service
                </a>{' '}
                and our{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.toriihomes.com/legal/privacy"
                  className="text-link"
                >
                  privacy policy
                </a>
              </span>
              <div>
                <div style={{ marginTop: isMobile() ? 20 : 24 }}>
                  <Button
                    disabled={!valid}
                    variant="contained"
                    size="medium"
                    className={classes.buttonMediumBlue}
                    style={{
                      width: '100%',
                      height: 56,
                      textTransform: 'capitalize',
                      borderRadius: 10,
                    }}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Sign up
                  </Button>
                </div>
                {this.props.children}
                <div
                  style={{
                    margin: '8px 0',
                    border: '1px solid #ABABAB',
                    width: '100%',
                  }}
                />
                <Button
                  onClick={this.nextSlide}
                  style={{
                    backgroundColor: 'transparent',
                  }}
                  className={classes.buttonSignupNavigation}
                >
                  <Typography
                    style={{
                      letterSpacing: 1.75,
                      textTransform: 'capitalize',
                      color: '#463264',
                      fontWeight: 600,
                    }}
                  >
                    More Options
                  </Typography>
                </Button>
              </div>
              {isMobile() && this.props.children}
            </div>
          </div>
        ),
      },
      {
        component: (
          <div
            ref={this.moreOptionsRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Button onClick={this.previousSlide} style={{ minWidth: 0 }}>
              <ArrowLeft size={32} color="#463264" />
            </Button>
            <div
              style={{
                marginTop: 30,
              }}
            >
              <span
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: '32px',
                  marginLeft: '12px',
                }}
              >
                More Options
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: 24,
              }}
            >
              <div
                className="sign-up-nav-button-container"
                style={{ marginBottom: 16, width: '100%' }}
              >
                <Button
                  className={`${classes.buttonSocialAuth} signup-button `}
                  style={{ width: '100%', height: 56 }}
                  onClick={this.handleGoogle}
                >
                  <GoogleLogo size={24} color="#2D2332" weight="regular" />
                  <span
                    className="auth-button-text"
                    style={{ marginLeft: '5px' }}
                  >
                    Sign up with Google
                  </span>
                </Button>
              </div>
              {/* <div
                className="sign-up-nav-button-container"
                style={{ width: '100%' }}
              >
                <Button
                  className={`${classes.buttonSocialAuth} signup-button `}
                  onClick={this.handleFacebook}
                  style={{ width: '100%', height: 56 }}
                >
                  <FacebookLogo size={24} color="#2D2332" weight="fill" />
                  <span
                    className="auth-button-text"
                    style={{ marginLeft: '5px' }}
                  >
                    Sign up with Facebook
                  </span>
                </Button>
              </div> */}
            </div>
          </div>
        ),
      },
    ];

    return (
      <SwipeableViews
        index={this.state.currentStep}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        className="col-xs-12 row middle-xs center-xs"
        disabled // To prevent swiping
        style={isMobile() ? { padding: 0 } : {}}
      >
        {steps.map((item: any, index: number) => (
          <div
            key={index}
            className={`${style.signupForm} col-xs-12 row middle-xs center-xs`}
          >
            {item.component}
          </div>
        ))}
      </SwipeableViews>
    );
  };

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, valid }) =>
          this.renderContents(handleSubmit, valid)
        }
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  provider: state.provider.provider,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(buttonStyles)(SignupForm)),
);
